.color{  
    color: #21E6C1 ;
 }
 
 .middleTric{
    font-size: 100px;
    color: rgba(92, 111, 71, 0.1);
 }
 
 .iconsColor{
    color: #21E6C1;
 }
 .bgCard{
    
    box-shadow: 0 1px 1px 0 rgb(92, 111, 71), 0 2px 50px 0 rgb(92, 111, 71); 
 }
 .imgSize2{
   width: 300px;
   height: 350px;
   border-radius: 5%;
}