.color{  
    color: #21E6C1 ;
 }
 
 .middleTric{
    font-size: 100px;
    color: rgba(92, 111, 71, 0.1);

 }
 .animations{
   display: none;
 }
 .movingArrow {
   position: absolute;
   bottom: 200;
   left: 50%;
   z-index: 9;
   color:#21E6C1;
   animation-name: movingArrow;
   animation-duration: 2s;
   animation-iteration-count: infinite;
   transform: translate(-50% , -50%);
}
@keyframes movingArrow{
   0%{ bottom: 200px; }
   100%{ bottom: 100px;} 
}
.bgCard{
    
   box-shadow: 0 1px 1px 0 rgb(92, 111, 71), 0 2px 50px 0 rgb(92, 111, 71); 
}
.idaBG{
   background-color:rgba(33, 230, 193, 0.1);
}

.imgAnime{
   overflow: hidden;
   position: relative;
}
.greenLayer{
position: absolute;
top: 0px;
left:0px;
background-color:rgba(33, 230, 193, 0.9);
width: 100%;
height: 100%;
opacity: 0%;
transition: all 0.5s;
color:  white;
}
.imgAnime img{
   transition: all 0.5s;}

.imgAnime:hover img{
   transform: scale(1.1);}

.imgAnime:hover .greenLayer{
   opacity: 100%;}