
.middleTric{
    font-size: 100px;
    color: rgba(92, 111, 71, 0.1);
}

.iconsColor{
    color: #21E6C1;
}
.bgCard{
    
    box-shadow: 0 1px 1px 0 rgb(92, 111, 71), 0 2px 50px 0 rgb(92, 111, 71); 
}
.formBorder{
  border: #21E6C1 2px solid !important;
}
.btnEdit{
    background-color: #21E6C1 !important;
    border: none !important;
    border-radius: 5px !important;
    color:white !important;
    padding: 10px;
   
}
.btnEdit:hover{
    background-color: #5C6F47 !important;
    transition: all 0.5s;
}
.anchorColor{
    color: #5C6F47 !important;
    text-decoration: none;
    
}