.color{  
    color: #21E6C1 ;
 }
 
 .middleTric{
    font-size: 100px;
    color: rgba(92, 111, 71, 0.1);
 }

 .bgCard{
    
    box-shadow: 0 1px 1px 0 rgb(92, 111, 71), 0 2px 50px 0 rgb(92, 111, 71); 
 }
 .linkPro{
   text-decoration: none !important;
   color: black;
   transition: all 0.5s;

 }
 .linkPro:hover{
   text-decoration: none !important;
   color: #21E6C1;
   transition: all 0.5s;
 }
 .imgSizeProj{
   width: 350px;
   height: 200px;
}